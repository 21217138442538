import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// 主页
const homeIndex = () => import(/* webpackChunkName: "group-one" */ "@/pages/index"); //首页
const selectRole = () => import(/* webpackChunkName: "group-one" */ "@/pages/login/select_role"); //选择角色
const codeLogin = () => import(/* webpackChunkName: "group-one" */ "@/pages/login/code_login"); //验证码登录
const myposted = () => import(/* webpackChunkName: "group-one" */ "@/pages/myposted/index"); //我发布的需求
const talentdemand = () => import(/* webpackChunkName: "group-one" */ "@/pages/talentdemand/index"); //不需要登录的人才需求


const router = new Router({
    mode: "history", //之前页面带有#号，history会影响之前的页面无法访问
    routes: [
        {
            // 首页
            path: "/",
            name: "homeIndex",
            component: homeIndex,
        }, 
        {
            // 选择角色
            path: "/select_role",
            name: "select-role",
            component: selectRole
        },
         {
            // 登录
            path: "/code_login",
            name: "code-login",
            component: codeLogin
        },
        {
            // 不需要登录的人才需求发布
            path: "/talent_demand",
            name: "talent-demand",
            component: talentdemand
        },
        {
            // 我发布的需求
            path: "/myposted",
            name: "myposted",
            component: myposted,
            meta: {
                requiresAuth: true
            }
        }, 
    ]
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (localStorage.getItem("GOYINGLIANTOKENAPP")) {
            next();
        } else {
            // let instance = Toast.info("请先登录");
            // setTimeout(() => {
            //     instance.hide();
            // }, 2000);
            next("/code_login");
        }
    } else {
        next(); // 确保一定要调用 next()
    }
});

export default router;
