import Vue from 'vue'
import App from './App.vue'
import router from "./router";//路由
import { Toast, Popup, Icon } from "mand-mobile";//ui组件引
import { globalFun } from "./utils/globalFun";//挂载全局方法

Vue.component(Icon.name, Icon);
Vue.component(Popup.name, Popup);
Vue.use(globalFun);

Vue.config.productionTip = false
new Vue({
    render: h => h(App),
    router
}).$mount('#app')


// 公共提示信息
Vue.prototype.$toast = function(txt, duration = 3000) {
    Toast.info(txt, duration);
};
// 隐藏提示信息
Vue.prototype.$toastHid = function() {
    Toast.hide();
};

// 加载中
Vue.prototype.$toastLoad = function(txt) {
    Toast.loading(txt);
};